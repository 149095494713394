$accent-yellow: #ffed00;
$white: #ffffff;

// accents
$accent-light-blue: #87c0da;
$accent-blue: #314ea3;
$accent-green: #a9c938;
$accent-orange: #d68a3c;
$accent-red: #c42327;

// blacks
$black: #000000;
$black-80: #000000cc;
$black-50: #00000080;
$black-15: #00000026;

// greys
$very-dark-grey: #1a1a18;
$intense-grey: #545454;
$dark-grey: #a1a1a1;
$grey-area: #b9b9b9;
$light-grey: #e6e6e6;
$very-light-gray: #f2f2f2;
$white-smock: #f7f7f7;
$tab-col: #111827;
