// Reverted for now, needs a bit more testing
@use 'styles/material.scss';

@use './assets/styles/colors' as colors;
@use './assets/styles/dialogs';
@use './assets/styles/fonts';
@use './assets/styles/reset';
@use './assets/styles/text';
@use './assets/styles/util';
@use './assets/styles/layout';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  min-height: 100vh;
  background-color: rgba(#000, 0.02);
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
  /* font-family: Arial, Helvetica, sans-serif; */
}

input {
  @apply tracking-normal;
}

.inspection-div {
  background-color: colors.$light-grey;
}

#input-div:hover {
  background-color: colors.$very-light-gray;
}

#input-div:hover .input-highlight {
  background-color: colors.$very-light-gray;
}

body.vertical-nav-open .cdk-overlay-connected-position-bounding-box {
  transform: translate(96px, -72px) !important;
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 3s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.centered-spinner {
  position: absolute;
  top: 45%;
  left: 45%;
}

.centered-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mat-mdc-select,
.mat-date-range-input-inner {
  // Using this method as !important didn't work with @apply
  font-size: 0.875rem !important/* 14px */;
  line-height: 1.25rem !important/* 20px */;
}

.mat-m dc-option,
button,
.mdc-form-field > label {
  @apply text-sm #{!important};
}

.mdc-dialog {
  .batch-bom {
    @apply static #{!important};
  }
}

.mat-mdc-icon-button.mat-mdc-button-base {
  line-height: 1;
}

// Inspection table column widths
.mat-column-derivedResult {
  min-width: 90px;
}

.inspections-container mat-card-content {
  min-height: calc(100vh - 320px);
  padding: 0;
  @apply border-t-gray-300;
  border-top-width: 1px;
}

.mdc-dialog .contained {
  max-height: calc(100vh - 200px) !important;
}

.mat-mdc-option.mdc-list-item {
  font-size: 14px;
}

.mat-mdc-table .mdc-data-table__row {
  height: 50px !important;
}

.mat-mdc-paginator-page-size {
  margin: 0 !important;
}

.mat-mdc-paginator-container {
  padding: 0 !important;
}
.mat-mdc-paginator-page-size-select {
  width: 68px !important;
}

.mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
  width: 180px !important;
}

.small-icon-button {
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important;
  padding: 6px !important;

  .mat-icon {
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;
  }

  .material-icons {
    font-size: 24px !important;
  }
}

/* Material Overrides */

.mat-mdc-unelevated-button.mat-primary {
  color: white !important;
}

.mdc-button {
  -webkit-font-smoothing: antialiased;
}

.notification-menu {
  .mat-mdc-menu-item {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid colors.$very-light-gray;
  }

  .unread {
    .mat-mdc-menu-item-text {
      font-weight: 700 !important;
    }
  }

  .mat-mdc-menu-content,
  .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    max-height: 300px;
    overflow-y: auto;
    font-size: 12px;
  }
}
