.fip-dialog-container > .mat-mdc-dialog-container {
  background: #ffffff;
  border-radius: 12px;
  padding: 0px !important;
  width: 500px;
}

.fip-dialog-success-header {
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fip-dialog-success-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fip-dialog-success-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  column-gap: 20px;
}

.custom-modal-body {
  display: flex;
  justify-items: center;
  position: absolute;
  max-width: 1200px;
  right: 23%;
  left: 23%;
  top: 7.81%;
  bottom: 7.81%;
  background: #ffffff;
}

.mat-mdc-dialog-container {
  padding: 0px !important;
}
