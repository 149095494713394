@use './colors';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .base-bold {
    @apply font-sans font-bold not-italic;
  }

  .base-normal {
    @apply font-sans font-normal not-italic;
  }

  .base-italic {
    @apply font-sans font-normal italic;
  }

  .typo-h1 {
    @apply base-bold text-s1  leading-lh-1;
  }

  .typo-h2 {
    @apply text-3xl;
  }

  .typo-h3 {
    @apply base-bold text-s3 leading-lh-3;
  }

  .typo-h4 {
    @apply base-bold text-s4 leading-lh-4;
  }

  .typo-h5 {
    @apply base-bold text-s5 leading-lh-5;
  }

  .typo-base {
    @apply base-normal text-s6 leading-lh-6;
  }

  .typo-base-italic {
    @apply base-italic text-s6 leading-lh-6;
  }

  .typo-base-bold {
    @apply base-bold text-s6 leading-lh-6;
  }

  .typo-small {
    @apply text-s7 leading-lh-7 font-sans font-normal not-italic;
  }

  .typo-small-italic {
    @apply base-italic text-s7 leading-lh-7;
  }

  .typo-small-bold {
    @apply base-bold text-s7 leading-lh-7;
  }

  .typo-tag {
    @apply base-bold leading-lh-8 text-sm;
  }

  .typo-tab-col-heading {
    @apply base-bold text-s8 leading-lh-8 text-tab-col;
  }

  .typo-icon-small {
    @apply base-bold text-s8 text-tab-col;
  }
}

:root {
  --mat-menu-item-label-text-size: 14px;
}
